import styled, { css } from 'styled-components';
import { PaletteColorType } from '../../../theme/type/palette-type';
import { getOpacity } from '../../../theme/utils/get-rgb';

export const Wrapper = styled.span.withConfig({
    shouldForwardProp: (prop) => !['variant', 'size', 'square'].includes(prop),
})<{
    variant: PaletteColorType;
    size: 'small' | 'medium' | 'large';
    square?: boolean;
}>(
    ({ theme, variant, size, square }) => css`
        display: inline-flex;
        border-radius: ${square ? theme.radius.small : '10rem'};
        color: ${theme.palette.color[variant].main};
        font-weight: 700 !important;
        justify-content: center;
        align-items: center;
        font-style: normal;
        text-transform: uppercase;
        transition: all ${theme.base.transition};

        ${variant &&
        css`
            background-color: ${getOpacity(theme.palette.color[variant].main, 0.1)};
        `}

        ${size === 'small' &&
        css`
            font-size: 1rem !important;
            line-height: 1.6rem !important;
            min-width: 1.6rem;
            height: 1.6rem;
            padding: 0 0.7rem;
        `}

        ${size === 'medium' &&
        css`
            font-size: ${theme.text.size.xsmall.fontSize} !important;
            line-height: ${theme.text.size.xsmall.lineHeight} !important;
            min-width: 1.9rem;
            height: 1.9rem;
            padding: 0 1rem;
        `}

        ${size === 'large' &&
        css`
            font-size: ${theme.text.size.small.fontSize} !important;
            line-height: ${theme.text.size.small.lineHeight} !important;
            min-width: 1.9rem;
            height: 2.6rem;
            padding: 0 1rem;
        `}
    `,
);
